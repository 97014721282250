@charset "utf-8";

/*marty type editing. to revert just delete or comment out.*/
/* a problem though is how to adjust main body text!! NONE of these do that. */
/* type scale */
$type-size-1 : 2em; 	//2.441em;  // ~39.056px
$type-size-2 : 1.4em; 	//1.953em;  // ~31.248px
$type-size-3 : 1.1em; 	//1.363em;  // ~25.008px
$type-size-4 : /*1em;*/ 	1.25em;   // ~20px
$type-size-5 : 0.9em;	//1em;      // ~16px
$type-size-6 : /*0.66em;*/ 	0.75em;   // ~12px
$type-size-7 : 0.6875em; // ~11px
$type-size-8 : 0.625em;  // ~10px

//$body-color : ;
//$background-color : ;
$text-color : #bdbdbd;
//$link-color : #009f9f;
$link-color: #008f8f;
//$masthead-link-color : ;

//disabling animations
//$intro-transition  : none;
//$global-transition : none;

// testing //
//$paragraph-indent : true;
//$indent-var : 26px;


@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes"; // main partials
